
export function formatBlogDetails(props) {
  const blogHeroImage = props.blogHeroImage?.data?.attributes
  const blog = {
    blogTitle: props.blogTitle,
    blogSubtitle: props.blogSubtitle,
    publishedDate: props.publishedDate,
    publication: props.publication,
    image: {
      url: blogHeroImage?.url,
      caption: blogHeroImage?.caption
    },
  }
  const relatedBlogs = props?.linkedBlogs?.map(a => {
    const blogImage = a.blogHeroImage
    return {
      blogTitle: a.blogTitle,
      blogSubtitle: a.blogSubtitle,
      publication: a.publication,
      publishedDate: a.publishedDate,
      image: blogImage ? {
        url: blogImage?.url
      } : null
    }
  })
  const contentBody = props?.blogContent?.map(a => {
    return {
      strapi_component: a.strapi_component,
      sectionBody: getSectionByComponentName(a.strapi_component, a)
    }
  })
  return { blogDetails: blog, relatedBlogs: relatedBlogs ?? [], blogBody: contentBody ?? [] }
}
export function formatLinkedBlogs(arr) {
  return arr.map(a => {
    return {
      blogTitle: a.blogTitle,
      subTitle: a.subTitle,
      seoTitle: a.seoTitle,
      seoDescription: a.seoDescription,
      image: {
        url: a.blogHeroImage?.url,
        caption: a.blogHeroImage?.caption
      },
    }
  })
}


function getSectionByComponentName(name, componentValue){
  switch (name) {
    case "section.blog-video-block":
      return componentValue.videoMedia
    case "section.blog-rich-editor-block":
      return componentValue.sectionBody
    case "section.blog-markdown-editor":
      return componentValue?.markdownBody?.data?.markdownBody
   
    default:
      return componentValue?.sectionBody?.data?.sectionBody
  }
}