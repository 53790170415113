import { formatBlogDetails } from "./helper-utility";

export async function getRelatedBlogs(selectedFilter) {
  try {
    const response = await fetch(`${process.env.GATSBY_BLOGCMS_URL}/api/blogs?sort[0]=publishedDate:desc&filters[categories][categoryKey][$eq]=${selectedFilter.categoryKey}&populate=*`);
    const result = await response.json();
    const blogs = result.data.map(a => {
      const imageAttributes = a.attributes?.blogHeroImage?.data?.attributes
      delete a?.attributes?.blogHeroImage
      return {
        ...a.attributes,
        id: a.id,
        image: imageAttributes ? imageAttributes : null,
      }
    })
    return blogs
  } catch (error) {
    return []
  }
}

export async function getBlogCategories() {
  try {
    const res = await fetch(`${process.env.GATSBY_BLOGCMS_URL}/api/categories?populate=*`);
    if (!res.ok) {
      return {
        error: "Throw error",
        isLoaded: true,
        filters: [],
        resultStatus: 500,
      }
    }
    const result = await res.json()

    const filters = result.data.map(info => {
      return {
        id: info.id,
        ...info.attributes,
      }
    })
    return { isLoaded: true, resultStatus: 200, filters: filters }
  } catch (e) {
    return { isLoaded: true, filters: [], resultStatus: 500, error: e }
  }
}
export async function getSelectedGroupBlogs(selectedFilter, searchValue) {
  let url = `sort[0]=publishedDate:desc&filters[categories][categoryKey][$eq]=${selectedFilter.categoryKey}&populate=*`
  if (searchValue) {
    url = `filters[blogTitle][$containsi]=${searchValue}&${url}`
  }
  try {
    const response = await fetch(`${process.env.GATSBY_BLOGCMS_URL}/api/blogs?${url}`);
    const result = await response.json();
    const blogs = result.data.map(a => {
      const imageAttributes = a.attributes?.blogHeroImage?.data?.attributes
      delete a?.attributes?.blogHeroImage
      return {
        ...a.attributes,
        id: a.id,
        image: imageAttributes ? imageAttributes : null,
      }
    })
    return blogs
  } catch (error) {
    return []
  }
}
export async function getBlogContent(title, relatedBlogs) {
  try {
    const res = await fetch(`${process.env.GATSBY_BLOGCMS_URL}/api/blogs?filters[blogTitle][$eq]=${title}&populate[blogHeroImage][populate]=*&populate[blogContent][populate]=*`);
    if (!res.ok) {
      return null
    }
    const result = await res.json()
    if (result.data?.length > 0) {
      const resultData = result.data?.[0]?.attributes
      let blog = formatBlogDetails(resultData)
      return {
        id: result.data?.[0]?.id,
        blogContent: resultData?.blogContent,
        blogDetails: blog.blogDetails,
        relatedBlogs: relatedBlogs.map(a => {
          const blogImage = a.blogHeroImage
          return {
            blogTitle: a.blogTitle,
            blogSubtitle: a.blogSubtitle,
            publication: a.publication,
            publishedDate: a.publishedDate,
            image: blogImage ? {
              url: blogImage?.url
            } : null
          }
        }),
      }
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}
